'use client';

import { AuthMachineProvider } from '@/context/auth/AuthMachineProvider';
import UnauthenticatedModal from '@/layouts/Unauthenticated/Modal';

import AuthModal from '../../AuthModal';

export default function Page() {
  return (
    <div className="relative mx-4 max-w-6xl text-white md:mt-20 lg:mx-10">
      <div className="relative px-4 pt-8 sm:w-full lg:p-0 ">
        <AuthMachineProvider type="signIn">
          <UnauthenticatedModal>
            <AuthModal />
          </UnauthenticatedModal>
        </AuthMachineProvider>
      </div>
    </div>
  );
}
